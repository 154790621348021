import { css } from "goober";

export const Content = () => css`
  & {
    display: grid;
    gap: var(--sp-6);
  }

  .hero {
    width: 100%;
    height: auto;
    aspect-ratio: 1016 / 218;
  }

  .center {
    width: 100%;
    max-width: 70ch;
    margin-inline: auto;
  }

  p {
    color: var(--shade1);

    u {
      color: var(--shade0);
      font-weight: 550;
    }
  }

  hr {
    width: 100%;
    height: 0;
    margin-block: var(--sp-4);
    border: none;
    border-bottom: 1px solid var(--shade3-25);
  }

  .download-link {
    color: var(--primary);
    font-weight: 700;
    text-decoration: underline;
  }

  details {
    > div,
    > p {
      padding: var(--sp-6);
      color: var(--shade1);
    }

    &[open] {
      summary {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: inset 0 -1px var(--card-inset-border);
      }
    }
  }
  summary {
    cursor: pointer;
    padding: var(--sp-4);
    background: var(--shade7);
    border-bottom-right-radius: var(--card-radii);
    border-bottom-left-radius: var(--card-radii);
    user-select: none;
  }
`;

export const Steps = () => css`
  & {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--sp-5);

    @container (inline-size <= 600px) {
      grid-template-columns: 1fr;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    aspect-ratio: 855 / 540;
    border-radius: var(--br);
    border: var(--sp-1) solid var(--shade10);
  }
`;
