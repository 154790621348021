import React from "react";
import ReactDOMServer from "react-dom/server";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import DownloadButtonWrapper from "@/app/DownloadButtonWrapper.jsx";
import { Content, Steps } from "@/game-deadlock/FAQ.style.jsx";
import ArrowRightIcon from "@/inline-assets/arrow-right2.svg";
import Container from "@/shared/ContentContainer.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader";
import { useDownloadLink } from "@/util/download-app.mjs";

function FAQ() {
  const { t } = useTranslation();
  const downloadUrl = useDownloadLink();

  const TutorialTooltip = ReactDOMServer.renderToStaticMarkup(
    <TutorialImages />,
  );

  return (
    <PageContainer>
      {!IS_APP && (
        <PageHeader
          title={t("deadlock:faq.blitzForDeadlock", "Blitz for Deadlock FAQ")}
        />
      )}
      <Container className={Content()}>
        <Card padding="0">
          <img
            src={`${appURLs.CDN}/blitz/deadlock/misc/faq/deadlock-faq-herov2.webp`}
            className="hero"
            width="1016"
            height="218"
            alt="Blitz for Deadlock"
          />
        </Card>
        {!IS_APP && (
          <>
            <p className="center">
              <Trans i18nKey="deadlock:faq.appRequired">
                The Blitz Desktop app is required for tracking your matches. If
                you don&apos;t already have Blitz installed be sure to{" "}
                <a href={downloadUrl} className="download-link">
                  download now
                </a>
                !
              </Trans>
            </p>
            <DownloadButtonWrapper
              uiLocation="DEADLOCK_FAQ_WEB_CTA"
              noClassOverride
              emphasis="high"
              iconRight={<ArrowRightIcon />}
              size="large"
              textColor="var(--shade0)"
              style={{ width: "fit-content", marginInline: "auto" }}
            >
              {t("common:getBlitzCTA", "Get Blitz Now")}
            </DownloadButtonWrapper>
            <hr />
          </>
        )}
        <p className="center">
          <Trans i18nKey="deadlock:faq.worksUnlikeOtherGames">
            Deadlock works a bit differently than our current games. If you have
            Blitz open while Deadlock is running we will save your match data
            anytime you{" "}
            <u data-tip={TutorialTooltip}>view a scoreboard within the game</u>.
            Meaning, after you complete a match,{" "}
            <u data-tip={TutorialTooltip}>and/or view an older match</u> in
            either your match history (even your friends), the match data will
            be saved!
          </Trans>
        </p>
        <p className="center">
          <Trans i18nKey="deadlock:faq.theMoreTheBetter">
            The more matches you view, the more data we can collect and build
            cool things like Builds, Tier Lists, Performance Tracking, and more!
          </Trans>
        </p>
        <hr />
        <Card padding="0" classNameOuter="center">
          <details>
            <summary className="type-title--bold">
              {t(
                "deadlock:faq.myOldMatchesAreMissing",
                "My old matches matches are missing",
              )}
            </summary>
            <p className="type-title--semi">
              <Trans i18nKey="deadlock:faq.myOldMatchesAreMissingDescription">
                If you played matches without Blitz running all you have to do
                is{" "}
                <u data-tip={TutorialTooltip}>
                  go to your in-game match history and view the scoreboard of
                  the match
                </u>{" "}
                you want to save. Blitz will save the match data for you.
              </Trans>
            </p>
          </details>
        </Card>
        <Card padding="0" classNameOuter="center">
          <details>
            <summary className="type-title--bold">
              {t(
                "deadlock:faq.playedWithoutBlitzRunning",
                "I played some games without Blitz running",
              )}
            </summary>
            <p className="type-title--semi">
              <Trans i18nKey="deadlock:faq.playedWithoutBlitzRunningDescription">
                Like above, any game you ever play(ed) without Blitz can be
                saved by{" "}
                <u>viewing the scordboard in your in-game match history</u>.
              </Trans>
            </p>
            <TutorialImages />
          </details>
        </Card>
      </Container>
    </PageContainer>
  );
}

export default FAQ;

function TutorialImages() {
  const { t } = useTranslation();

  return (
    <div className={Steps()}>
      <div className="step">
        <h4 className="type-subtitle--bold shade0">
          {t("common:step1", "Step 1")}
        </h4>
        <p className="type-caption--semi shade1">
          {t(
            "deadlock:faq.step1Description",
            "On the main screen click into your profile",
          )}
        </p>
        <img
          src={`${appURLs.CDN}/blitz/deadlock/misc/faq/profile-match-step1.webp`}
          width="478"
          height="302"
        />
      </div>
      <div className="step">
        <h4 className="type-subtitle--bold shade0">
          {t("common:step2", "Step 2")}
        </h4>
        <p className="type-caption--semi shade1">
          {t(
            "deadlock:faq.step2Description",
            "Click the 'View All Match History link'",
          )}
        </p>
        <img
          src={`${appURLs.CDN}/blitz/deadlock/misc/faq/profile-match-step2.webp`}
          width="478"
          height="302"
        />
      </div>
      <div className="step">
        <h4 className="type-subtitle--bold shade0">
          {t("common:step3", "Step 3")}
        </h4>
        <p className="type-caption--semi shade1">
          {t(
            "deadlock:faq.step3Description",
            "Click on the match(es) you want to save",
          )}
        </p>
        <img
          src={`${appURLs.CDN}/blitz/deadlock/misc/faq/profile-match-step3.webp`}
          width="478"
          height="302"
        />
      </div>
      <div className="step">
        <h4 className="type-subtitle--bold shade0">
          {t("common:step4", "Step 4")}
        </h4>
        <p className="type-caption--semi shade1">
          {t(
            "deadlock:faq.step4Description",
            "That's it! Blitz has saved this data for you!",
          )}
        </p>
        <img
          src={`${appURLs.CDN}/blitz/deadlock/misc/faq/profile-match-step4.webp`}
          width="478"
          height="302"
        />
      </div>
    </div>
  );
}

export function meta() {
  return {
    title: ["deadlock:faq.blitzForDeadlock", "Blitz for Deadlock FAQ"],
    description: [],
  };
}
